import React, { ReactNode, useEffect, useRef } from 'react';

interface OutsideAlerterProps {
  children: ReactNode;
  onClickOutside?: (event: MouseEvent) => void;
}

const OutsideAlerter: React.FC<OutsideAlerterProps> = ({
  children,
  onClickOutside
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      onClickOutside &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      onClickOutside(event);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
