import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { CheckCircle, ErrorCircle } from '@icons/index';
import { useVerifyCallback } from '@services/auth/use-verify-callback';
import { Colors } from '@utils/ColorUtils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const VerifyPage = () => {
  const { secret } = useParams();
  const { isSuccess, isLoading, isError } = useVerifyCallback(secret);
  const { t } = useTranslation('register');

  return (
    <>
      <MetaTags title="Verify" />
      <div className="mt-[-2%] flex h-screen w-full flex-col items-center justify-center">
        {isLoading && <ClipLoader color={Colors.PRIMARY} />}
        {isSuccess && (
          <div className="flex flex-col items-center gap-6">
            <CheckCircle
              color={Colors.success}
              width={'3rem'}
              height={'3rem'}
            />
            <Typography variant="h2" className="!w-[70%] text-center">
              {t('verification_success')}
            </Typography>
          </div>
        )}
        {isError && (
          <div className="flex flex-col items-center gap-6">
            <ErrorCircle color={Colors.error} width={'3rem'} height={'3rem'} />
            <Typography variant="h2" className="!w-[70%] text-center">
              {t('verification_failed')}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};
