import Attachment from './attachment.svg?react';
import BackArrow from './back-arrow.svg?react';
import Chat from './chat.svg?react';
import CheckCircle from './check-circle.svg?react';
import Chevron from './chevron.svg?react';
import ErrorCircle from './error-circle.svg?react';
import Glasses from './glasses.svg?react';
import Info from './info.svg?react';
import Search from './search.svg?react';
import SendArrow from './send-arrow.svg?react';

const ChevronUp = ({ ...props }) => (
  <Chevron style={{ transform: 'rotate(180deg)' }} {...props} />
);

export {
  Attachment,
  BackArrow,
  Chat as ChatIcon,
  CheckCircle,
  Chevron as ChevronDown,
  ChevronUp,
  ErrorCircle,
  Glasses,
  Info,
  Search,
  SendArrow
};
