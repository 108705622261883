import { UserAccount } from '@models/UserAccount';
import { BASE_URL, getJWT, KoraApi } from '@services/api';
import axios from 'axios';
import useSWR from 'swr';

const meFetcher = async (url: string) => {
  try {
    await KoraApi.autoRefreshToken();
    const response = await axios
      .get(BASE_URL + `${url}`, {
        headers: { Authorization: getJWT() }
      })
      .then(res => res.data);
    return response;
  } catch (error) {
    return null;
  }
};

const useAccount = () => {
  const { data, error, isLoading, mutate } = useSWR('/account', meFetcher);
  if (!data) return { user: null, error, isLoading, mutate };

  return { user: UserAccount.fromJson(data), error, isLoading, mutate };
};

export default useAccount;
