import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ProfilePictureUploadProps {
  initialImage?: string | null;
  onImageUpload?: (file: File) => void;
}

export const ProfilePictureUpload = ({
  initialImage,
  onImageUpload
}: ProfilePictureUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<string | null>(initialImage || null);
  const { t } = useTranslation('profile');

  useEffect(() => {
    setImage(initialImage || null);
  }, [initialImage]);

  const handleOverlayClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }

    if (file && onImageUpload) {
      onImageUpload(file);
    }
  };

  return (
    <div
      className={`relative flex min-h-[200px] w-full items-center justify-center rounded-[20px] hover:cursor-pointer ${image ? 'hover:[&>div]:opacity-60' : ''}`}
      onClick={handleOverlayClick}
    >
      {image ? (
        <img
          src={image}
          alt="Profile picture"
          className="h-full max-h-[200px] w-full rounded-[20px] object-cover"
        />
      ) : null}
      <div
        className={`absolute bottom-0 left-0 right-0 top-0 flex h-full w-full items-center justify-center rounded-[20px] bg-white font-semibold underline ${image ? 'opacity-0' : ''} transition duration-200`}
      >
        {t('upload_picture')}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onChange={handleFileChange}
      />
    </div>
  );
};
