import { KoraApi } from '@services/api';

interface ProfileSummaryUpdateProps {
  summary: string;
  fromMessage?: string;
}

export const useProfileSummaryUpdate = async ({
  summary,
  fromMessage
}: ProfileSummaryUpdateProps) => {
  if (!summary) return;

  const response = await KoraApi.put('/account/profile-summary', {
    summary,
    fromMessage
  });

  return response.data;
};
