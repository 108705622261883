import { Button } from '@elements/Button';
import { Colors } from '@utils/ColorUtils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface ProgressCircleProps {
  percentage: number;
}

export const ProgressCircle = ({ percentage }: ProgressCircleProps) => {
  const { t } = useTranslation('profile');

  return (
    <>
      <style>
        {`

      .circular-progress {
        --size: 250px;
        --half-size: calc(var(--size) / 2);
        --stroke-width: 20px;
        --radius: calc((var(--size) - var(--stroke-width)) / 2);
        --circumference: calc(var(--radius) * pi * 2);
        --dash: calc((var(--progress) * var(--circumference)) / 100);
        animation: progress-animation 1s linear 0s 1 forwards;
      }

      .circular-progress circle {
        cx: var(--half-size);
        cy: var(--half-size);
        r: var(--radius);
        stroke-width: var(--stroke-width);
        fill: none;
        stroke-linecap: round;
      }

      .circular-progress circle.bg {
        stroke: #ddd;
      }

      .circular-progress circle.fg {
        transform: rotate(-90deg);
        transform-origin: var(--half-size) var(--half-size);
        stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
        transition: stroke-dasharray 0.3s linear 0s;
        stroke: ${percentage === 100 ? Colors.primary : '#3B55F6'};
      }

      @property --progress {
        syntax: "<number>";
        inherits: false;
        initial-value: 0;
      }

      @keyframes progress-animation {
        from {
          --progress: 0;
        }
        to {
          --progress: ${percentage};
        }
      }
      `}
      </style>
      <div className="flex flex-col items-center justify-center">
        <div className="relative mb-8 flex items-center justify-center">
          <svg
            width="250"
            height="250"
            viewBox="0 0 250 250"
            className="circular-progress"
          >
            <circle className="bg"></circle>
            <circle className="fg"></circle>
          </svg>
          <div className="absolute left-0 top-0 flex aspect-square h-full w-full items-center justify-center rounded-full">
            <span className="text-3xl font-bold text-black">{percentage}%</span>
          </div>
        </div>
        <div className="w-full text-center">
          <Link to="/intake">
            <Button variant={percentage === 100 ? 'info' : 'secondary'}>
              {percentage === 100
                ? t('intake_completed')
                : t('complete_intake')}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
