import { BackArrow } from '@icons/index';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const BackButton = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(-1)}
      className="flex items-center gap-2 text-xl font-semibold underline"
    >
      <BackArrow /> {t('back')}
    </button>
  );
};
