import { LandingPage } from '@pages/Landing';
import * as Sentry from '@sentry/react';
import './App.css';
import { Header } from './Header';
import './i18n';
import { KoraRoutes } from './routes';

function App() {
  return (
    <div className="flex h-[100vh] flex-col">
      <Header />
      <div className="relative mt-[88px] flex w-full flex-1">
        {import.meta.env.VITE_ENV === 'production' ? (
          <LandingPage />
        ) : (
          <KoraRoutes />
        )}
        <div className="absolute -z-20 h-full w-full">
          <div className="absolute bottom-0 left-0 h-[350px] w-[70%] rounded-[0px_100px_0px_0px] bg-[#DBE2FD]"></div>
          <div className="absolute right-0 top-[-20px] h-[400px] w-[70%] rounded-[0px_0px_0px_100px] bg-[#ECECE3]"></div>
        </div>
      </div>
    </div>
  );
}

export default Sentry.withProfiler(App);
