import { Button } from '@elements/Button';
import { useToast } from '@elements/Toast';
import { AccountType } from '@models/UserAccount';
import { useGoogleLogin } from '@react-oauth/google';
import useGoogleCallback from '@services/auth/use-google-callback';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface IGoogleLoginButton {
  label: string;
  mode?: 'register' | 'login';
  accountType?: AccountType;
}

export const GoogleLoginButton = ({
  label,
  mode = 'register',
  accountType
}: IGoogleLoginButton) => {
  const { t } = useTranslation('register');
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const { error } = useToast();

  useGoogleCallback(mode, accessToken, accountType);

  const googleLogin = useGoogleLogin({
    onSuccess: async response => {
      setAccessToken(response.access_token);
    },
    onError: () => {
      error(t('google_failed'));
    }
  });

  return (
    <Button
      variant="info"
      className="mx-auto w-80 bg-white"
      onClick={() => googleLogin()}
    >
      {label}
    </Button>
  );
};
