import { Candidate } from './candidate';
import { Employer } from './Employer';

export enum AccountType {
  CANDIDATE = 'CANDIDATE',
  EMPLOYER = 'EMPLOYER'
}

// Make UserAccount generic, with `T` being the type of accountInfo
export class UserAccount<T extends Candidate | Employer | null> {
  constructor(
    public id: string,
    public email: string,
    public accountType: AccountType,
    public authProvider: string,
    public accountInfo: T // `T` can be `Candidate`, `Employer`, or `null`
  ) {}

  static fromJson(json: any): UserAccount<Candidate | Employer | null> {
    let info: Candidate | Employer | null = null;
    if (json.accountInfo) {
      if (json.accountType === AccountType.CANDIDATE) {
        info = Candidate.fromJson(json.accountInfo);
      } else if (json.accountType === AccountType.EMPLOYER) {
        info = Employer.fromJson(json.accountInfo);
      }
    }
    return new UserAccount(
      json.id,
      json.email,
      json.accountType,
      json.authProvider,
      info
    );
  }
}
