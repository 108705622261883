import { Status } from './Status';

export class Resume {
  constructor(
    public id?: string,
    public originalFilePath?: string,
    public parsedFilePath?: string,
    public educationsParsed?: boolean,
    public workExperiencesParsed?: boolean,
    public skillsParsed?: boolean,
    public status?: Status
  ) {}

  static fromJson(json: any): Resume {
    return new Resume(
      json.id,
      json.originalFilePath,
      json.parsedFilePath,
      json.educationsParsed,
      json.workExperiencesParsed,
      json.skillsParsed,
      json.status
    );
  }
}
