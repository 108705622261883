import OutsideAlerter from '@elements/OutsideAlerter';
import { ChevronDown, ChevronUp } from '@icons/index';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export interface MenuItemProps {
  title: string;
  to?: string;
  className?: string;
  level?: number;
  children?: React.ReactNode;
  [key: string]: any;
}
export const MenuItem = ({
  title,
  to,
  children,
  className,
  ...props
}: MenuItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const Container = ({
    children: containerChildren
  }: {
    children: React.ReactNode;
  }) => {
    if (to) {
      return (
        <Link
          to={to ? to : ''}
          onClick={() => {
            if (!children || to) return;
            setIsExpanded(!isExpanded);
          }}
          className={`font-semibold text-primary no-underline hover:underline ${className}`}
          {...props}
        >
          {containerChildren}
        </Link>
      );
    } else {
      return (
        <div
          onClick={() => {
            if (!children) return;
            setIsExpanded(!isExpanded);
          }}
          className={`font-semibold no-underline hover:cursor-pointer hover:underline ${className}`}
          {...props}
        >
          {containerChildren}
        </div>
      );
    }
  };
  return (
    <div className="relative">
      <Container>
        <p className="flex items-center gap-3 text-primary">
          {title}
          {children && <>{isExpanded ? <ChevronUp /> : <ChevronDown />}</>}
        </p>
      </Container>
      {isExpanded && (
        <OutsideAlerter
          onClickOutside={() => {
            setIsExpanded(false);
          }}
        >
          <div
            onClick={() => setIsExpanded(false)}
            className="absolute left-[-5px] top-[30px] flex flex-col rounded-[20px] bg-white p-[16px_24px] shadow-[0px_4px_8px_0px_rgba(0,0,0,0.08),0px_-2px_8px_0px_rgba(0,0,0,0.08)]"
          >
            {children}
          </div>
        </OutsideAlerter>
      )}
    </div>
  );
};
