import { useToast } from '@elements/Toast';
import { AccountType } from '@models/UserAccount';
import { KoraApi } from '@services/api';
import { isAxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface useRegistrationProps {
  email: string | undefined;
  password: string | undefined;
  repeatPassword: string | undefined;
  companyName?: string | undefined;
  acceptedTerms: boolean | undefined;
  accountType?: AccountType;
}

export const useRegistration = ({
  email,
  password,
  repeatPassword,
  companyName,
  acceptedTerms,
  accountType = AccountType.CANDIDATE
}: useRegistrationProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const toast = useToast();
  const { t } = useTranslation('register');

  const register = async () => {
    setLoading(true);
    try {
      await KoraApi.post('/auth/register', {
        email,
        password,
        companyName,
        accountType
      });
      setSuccess(true);
    } catch (error) {
      if (
        isAxiosError(error) &&
        error.response?.status === 400 &&
        error.response?.data.detail === 'user_exists'
      ) {
        toast.error(t('user_exists'));
      } else {
        toast.error(t('failed_register'));
      }
    }
    setLoading(false);
  };

  const memoized = useMemo(() => {
    const isValidEmail = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    const isFormValid =
      email &&
      (accountType === AccountType.CANDIDATE || companyName) &&
      isValidEmail &&
      acceptedTerms &&
      password &&
      repeatPassword &&
      password === repeatPassword;

    return {
      isFormValid,
      isSuccess: success,
      isLoading: loading,
      register
    };
  }, [
    email,
    password,
    accountType,
    repeatPassword,
    acceptedTerms,
    loading,
    register,
    success
  ]);

  return memoized;
};
