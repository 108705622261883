import { Link } from 'react-router-dom';
import { MenuItemProps } from './MenuItem';

export const MenuSubItem = ({
  title,
  to,
  className,
  ...props
}: MenuItemProps) => {
  return (
    <Link
      to={to ? to : ''}
      className="hover:bg-gray-100 rounded-[8px] p-2 font-normal no-underline"
      {...props}
    >
      <p>{title}</p>
    </Link>
  );
};
