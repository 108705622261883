import { Candidate } from '@models/candidate';
import { Employer } from '@models/Employer';
import { UserAccount } from '@models/UserAccount';
import useAccount from '@services/account/use-account';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { io } from 'socket.io-client';

// Define `IUserContext` to take children as its prop
export interface IUserContext {
  children: ReactNode;
}

// Generic UserContextType that includes the generic `T` for accountInfo type
export interface UserContextType<T extends Candidate | Employer | null> {
  user: UserAccount<T> | null;
  isLoggedIn: boolean;
  isLoading?: boolean;
  mutate: () => void;
}

// Using a generic type `T` for the context, defaulting to `any`
export const AccountContext = createContext<UserContextType<any>>({
  user: null,
  isLoggedIn: false,
  isLoading: true,
  mutate: () => {}
});

export const AccountContextProvider = ({ children }: IUserContext) => {
  const { user, isLoading, mutate } = useAccount();

  useEffect(() => {
    if (user) {
      const socket = io(import.meta.env.VITE_API_URL);
      console.log('Connecting to user updates');
      socket.emit('join', 'user-updates-' + user.id);
      socket.on('connection', socket => {
        console.log('Connected to user updates');
        socket.join(user.id);
      });
      socket.on('message', () => {
        console.log('User updated');
        mutate();
      });
      return () => {
        socket.disconnect();
      };
    }
  }, [user]);
  return (
    <AccountContext.Provider
      value={{ user, isLoggedIn: Boolean(user), isLoading, mutate }}
    >
      {children}
    </AccountContext.Provider>
  );
};

// Adjusted hook to include generic type `T`
export const useAccountContext = <T extends Candidate | Employer | null>() =>
  useContext(AccountContext) as UserContextType<T>;
