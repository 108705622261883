interface IContainer {
  children?: React.ReactNode;
  className?: string;
}
export const Container = ({ className, children }: IContainer) => {
  return (
    <div
      className={`container mx-auto mt-8 ${className ? className : ''}`}
      data-testid="container"
    >
      {children}
    </div>
  );
};
