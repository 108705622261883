export interface IRoundedContainer {
  className?: string;
  children?: React.ReactNode;
  [key: string]: any;
}
export const RoundedContainer = ({
  className,
  children,
  ...props
}: IRoundedContainer) => {
  return (
    <div
      className={`${className} rounded-[4px_20px_20px_20px] bg-[rgba(255,255,255,0.6)] p-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.20)]`}
      {...props}
    >
      {children}
    </div>
  );
};
