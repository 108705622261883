import { ChatBox } from '@components/chat/ChatBox';
import { BackButton } from '@elements/BackButton';
import { Container } from '@elements/Container';
import { ProgressBar } from '@elements/ProgressBar';
import { Typography } from '@elements/Typography';
import { Employer } from '@models/Employer';
import { useChat } from '@services/chat/use-chat';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

export const EmployerIntakePage = () => {
  const { t } = useTranslation('chat', {
    keyPrefix: 'employer_intake'
  });
  const { user } = useAccountContext<Employer>();
  const [currentStep, setCurrentStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  const { completeConversation } = useChat({
    about: { aboutType: 'UserAccount', aboutId: user!.id }
  });

  const handleCompleteAction = useCallback(async () => {
    console.log('handleCompleteAction');
    try {
      await completeConversation();
      // You might want to add some logic here after the conversation is completed
      // For example, updating the UI or navigating to another page
    } catch (error) {
      console.error('Error completing conversation:', error);
    }
  }, [completeConversation]);

  useEffect(() => {
    let _step = 1;
    const fields = user?.accountInfo;
    if (fields) {
      const {
        companyName,
        website,
        phone,
        industry,
        address,
        userDescription
      } = fields;
      if (companyName && website && phone && industry && address) {
        _step = 2;
      }
      if (userDescription) {
        _step = 3;
        setIsComplete(true);
      }
    }
    setCurrentStep(_step);
  }, [user]);

  const steps = [
    { label: t('progress_bar.step_1'), active: currentStep === 1 },
    { label: t('progress_bar.step_2'), active: currentStep === 2 },
    { label: t('progress_bar.step_3'), active: currentStep === 3 }
  ];

  const Header = () => {
    return (
      <Container>
        <div className="grid grid-cols-[1fr,4fr,1fr] py-4">
          <div className="flex items-start justify-start gap-2 font-bold">
            <BackButton />
          </div>
          <div className="w-full text-center">
            <Typography variant="h2" className="mb-3 text-primary">
              {t('title')}
            </Typography>
            <Typography variant="body1" className="mb-4 text-secondary">
              {t('description')}
            </Typography>
            <ProgressBar steps={steps} completeAction={handleCompleteAction} />
          </div>
        </div>
      </Container>
    );
  };
  return (
    <div className="flex h-full max-h-[calc(100vh-90px)] w-full flex-col">
      <Header />
      {user && (
        <ChatBox
          about={{ aboutType: 'UserAccount', aboutId: user.id }}
          notification={
            isComplete ? t('complete_profile_notification') : undefined
          }
        />
      )}
    </div>
  );
};
