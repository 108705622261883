import logo from '@assets/logo.svg';
import { MenuItem } from '@components/header/MenuItem';
import { MenuSubItem } from '@components/header/MenuSubItem';
import { Button } from '@elements/Button';
import { AccountType } from '@models/UserAccount';
import { useLogout } from '@services/auth/use-logout';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';
export const Header = () => {
  const { isLoggedIn, user } = useAccountContext();
  const { t } = useTranslation('header');

  const UserMenu = () => {
    const logout = useLogout();
    return (
      <div className="flex gap-10">
        <MenuItem title="Account">
          {user?.accountType === AccountType.EMPLOYER ? (
            <MenuSubItem to="/settings" title={t('settings')} />
          ) : (
            <MenuSubItem to="/profile" title={t('profile')} />
          )}
          <MenuSubItem title={t('logout')} onClick={logout} />
        </MenuItem>
        {user?.accountType === AccountType.EMPLOYER && (
          <>
            <MenuItem
              title={t('employer.recruitment_funnel')}
              to="/recruitment-funnel"
            />
            <MenuItem title={t('employer.vacancies')} to="/vacancies" />
          </>
        )}
      </div>
    );
  };

  if (import.meta.env.VITE_ENV === 'production') {
    return (
      <div className="fixed z-10 flex h-[80px] w-full flex-[0_1_auto] justify-center rounded-[0px_0px_20px_20px] !bg-[#F5F5F0] p-0 shadow-[0px_25px_30px_0px_rgba(0,0,0,0.04)]">
        <img src={logo} />
      </div>
    );
  }

  return (
    <div className="fixed z-10 flex w-full flex-[0_1_auto] justify-between rounded-[0px_0px_20px_20px] !bg-[#F5F5F0] p-[24px_40px] shadow-[0px_25px_30px_0px_rgba(0,0,0,0.04)]">
      <div className="flex items-center gap-2">
        <Link to="/">
          <img src={logo} className="me-10 h-[40px]" />
        </Link>
        {isLoggedIn && <UserMenu />}
      </div>
      <div className="flex gap-4">
        {!isLoggedIn && (
          <>
            <Link to="/register">
              <Button>{t('create_account')}</Button>
            </Link>
            <Link to="/login">
              <Button>{t('login')}</Button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
