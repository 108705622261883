import { Alert } from '@elements/Alert';
import { Button } from '@elements/Button';
import { Input } from '@elements/Input';
import { PlaceInput } from '@elements/PlaceInput';
import { Typography } from '@elements/Typography';
import { Info } from '@icons/index';
import { Employer } from '@models/Employer';
import { AccountType } from '@models/UserAccount';
import { useEmployerPatch } from '@services/user/use-employer-patch';
import { Colors } from '@utils/ColorUtils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useAccountContext } from 'src/providers/UserContext';
import { AccountDescription } from '../../AccountDescription';

const Overview = () => {
  const { user } = useAccountContext<Employer>();
  const navigate = useNavigate();

  const { t } = useTranslation('settings', { keyPrefix: 'overview' });

  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const {
    patch,
    canSave,
    isSaving,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    companyName,
    setCompanyName,
    companyWebsite,
    setCompanyWebsite,
    address,
    setAddress,
    descriptionUrl,
    setDescriptionUrl,
    phone,
    setPhone,
    industry,
    setIndustry
  } = useEmployerPatch();

  useEffect(() => {
    if (user?.accountType === AccountType.CANDIDATE) {
      navigate('/settings');
    }
  }, [user]);

  const onSave = async () => {
    setIsEditingDescription(false);
    await patch();
  };

  return (
    <>
      {user?.accountInfo.hasPendingJobs && (
        <Alert
          variant="info"
          icon={
            <ClipLoader size="14" color={Colors.primary} className="me-2" />
          }
        >
          {t('pending_website_parse')}
        </Alert>
      )}
      <div className="mb-6">
        <Typography variant="h2">{t('personal_information')}</Typography>
        <div className="grid grid-cols-[1fr,1fr] gap-[24px] py-[24px]">
          <Input
            label={t('first_name')}
            variant="compact"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value);
            }}
          />
          <Input
            label={t('last_name')}
            variant="compact"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value);
            }}
          />
          <Input
            label={t('email')}
            disabled={user?.authProvider !== 'email'}
            variant="compact"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="mb-6">
        <Typography variant="h2">{t('company_information')}</Typography>
        <Typography variant="caption" className="mt-1 flex text-secondary">
          <Info fill="#3B55F6" className="mx-1" width={14} />
          {t('website_hint')}
        </Typography>
        <div className="grid grid-cols-[1fr,1fr] gap-[24px] py-[24px]">
          <Input
            label={t('company_name')}
            variant="compact"
            value={companyName}
            error={
              !companyName || companyName.length === 0
                ? t('company_name_required')
                : undefined
            }
            onChange={e => {
              setCompanyName(e.target.value);
            }}
          />
          <Input
            label={t('company_website')}
            variant="compact"
            value={companyWebsite}
            onChange={e => {
              setCompanyWebsite(e.target.value);
            }}
          />
          <PlaceInput
            defaultValue={address}
            inputProps={{ variant: 'compact', label: t('company_address') }}
            onSelect={_address => {
              setAddress(_address);
            }}
          />
          <Input
            label={t('phone')}
            variant="compact"
            value={phone}
            onChange={e => {
              setPhone(e.target.value);
            }}
          />
          <Input
            label={t('industry')}
            variant="compact"
            value={industry}
            onChange={e => {
              setIndustry(e.target.value);
            }}
          />
        </div>
        <div className="py-6">
          <Button
            disabled={!canSave}
            variant="info"
            onClick={onSave}
            className="flex items-center"
          >
            {t('save')}
            {isSaving ? (
              <ClipLoader color="dark" size={12} className="ml-2" />
            ) : (
              ''
            )}
          </Button>
        </div>
        <div className="my-8">
          <Typography variant="h2" className="mb-6">
            {t('summary_of', {
              name: companyName ?? t('your_company')
            })}
          </Typography>
          {descriptionUrl ? (
            <AccountDescription descriptionUrl={descriptionUrl} />
          ) : (
            <>
              <Typography variant="caption" className="mb-6 text-dark">
                {t('complete_intake_description')}
              </Typography>
              <Link to="/intake">
                <Button variant="secondary">{t('complete_intake')}</Button>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Overview;
