import { useToast } from '@elements/Toast';
import { KoraApi } from '@services/api';
import { useState } from 'react';
import { useAccountContext } from 'src/providers/UserContext';

export const useResumeUpload = () => {
  const { mutate } = useAccountContext();
  const [isLoading, setIsLoading] = useState(false);

  const { success, error: errorToast } = useToast();

  const uploadResume = async (file: File | null) => {
    if (!file) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('resume', file);
    try {
      await KoraApi.post('/account/resume', formData);
      mutate();
      success('Resume uploaded successfully');
    } catch (error) {
      errorToast('Failed to upload resume');
    } finally {
      setIsLoading(false);
    }
  };

  return { upload: uploadResume, isLoading };
};
