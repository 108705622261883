interface ITypography {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'smallTitle'
    | 'body1'
    | 'body2'
    | 'caption';
  children: React.ReactNode;
  className?: string;
}

export const Typography = ({
  variant = 'body1',
  className,
  children
}: ITypography) => {
  if (variant === 'h1')
    return (
      <h1
        className={`font-semibold sm:text-[2rem] md:text-[2.5rem] ${className}`}
      >
        {children}
      </h1>
    );
  if (variant === 'h2')
    return (
      <h2 className={`text-3xl font-semibold ${className}`}>{children}</h2>
    );

  if (variant === 'h3')
    return (
      <h3 className={`text-[1.75rem] font-semibold ${className}`}>
        {children}
      </h3>
    );

  if (variant === 'h4')
    return (
      <h4 className={`text-[1.5rem] font-semibold ${className}`}>{children}</h4>
    );

  if (variant === 'h5')
    return (
      <h5 className={`text-[1.25rem] font-semibold ${className}`}>
        {children}
      </h5>
    );

  if (variant === 'smallTitle')
    return (
      <p className={`text-normal font-semibold ${className}`}>{children}</p>
    );

  if (variant === 'caption')
    return <p className={`text-sm ${className}`}>{children}</p>;
  if (variant === 'body2')
    return <p className={`text-xs ${className}`}>{children}</p>;
  return <p className={`text-${variant} ${className}`}>{children}</p>;
};
