import { Helmet } from 'react-helmet';

interface IPageTitle {
  title: string;
}
export const MetaTags = ({ title }: IPageTitle) => {
  return (
    <Helmet>
      <title>{title} - Kora</title>
      <meta
        name="description"
        content="Kora is a platform that connects job seekers with employers."
      />
      <meta
        name="keywords"
        content="job, career, employer, employee, work, remote"
      />
      <meta name="author" content="LaughNCode" />
    </Helmet>
  );
};
