import { Button } from '@elements/Button';
import { Divider } from '@elements/Divider';
import { Typography } from '@elements/Typography';
import { CheckCircle, Glasses } from '@icons/index';
import { Candidate } from '@models/candidate';
import { Status } from '@models/Status';
import { useResumeUpload } from '@services/account/use-resume-upload';
import { useResumeDelete } from '@services/user/use-resume-delete';
import { ReactNode, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { useAccountContext } from 'src/providers/UserContext';

export const ProfileCTASection = () => {
  const { t } = useTranslation('profile', { keyPrefix: 'cta' });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { upload, isLoading: isUploadingResume } = useResumeUpload();
  const { user } = useAccountContext<Candidate>();
  const { deleteResume } = useResumeDelete();

  const resumeStatusComponent = useMemo(() => {
    switch (user?.accountInfo?.resume?.status) {
      case Status.BUSY:
        return (
          <p className="flex items-center text-primary">
            <Glasses className="mr-2 h-4 w-4 fill-primary" />
            {t('reading_resume')}
          </p>
        );
      case Status.DONE:
        return (
          <p className="flex items-center text-primary">
            <CheckCircle className="mr-2 h-4 w-4 fill-primary" />
            {t('resume_success')}
          </p>
        );
      default:
        return null;
    }
  }, [user?.accountInfo?.resume?.status]);

  return (
    <div className="p-6">
      <CTA
        title={t('linkedin_title')}
        description={t('linkedin_description')}
        cta={t('linkedin_button')}
      />
      <Divider className="my-4" />
      <CTA
        title={t('resume_title')}
        description={t('resume_description')}
        statusMessage={resumeStatusComponent}
        cta={
          <>
            {isUploadingResume && (
              <ClipLoader size={12} color="#000" className="mr-2" />
            )}
            {user?.accountInfo?.resume
              ? t('resume_button_remove')
              : t('resume_button')}
          </>
        }
        onClick={() => {
          if (user?.accountInfo?.resume) deleteResume();
          else fileInputRef.current?.click();
        }}
      />
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="application/pdf"
        onChange={e => upload(e.target.files?.[0] ?? null)}
      />
      <Divider className="my-4" />
      <CTA
        title={t('referral_title')}
        description={t('referral_description')}
        cta={t('referral_button')}
      />
    </div>
  );
};

interface ICTA {
  title: string | ReactNode;
  description: string;
  cta: string | ReactNode;
  statusMessage?: string | ReactNode;
  onClick?: () => void;
}

const CTA = ({ title, description, cta, statusMessage, onClick }: ICTA) => {
  return (
    <div className="flex flex-col gap-3">
      <Typography variant="smallTitle">{title}</Typography>
      {statusMessage && <div className="text-xs">{statusMessage}</div>}
      <div className="text-xs">{description}</div>
      <div>
        <Button onClick={onClick} size="xs" className="p-[6px_16px]">
          {cta}
        </Button>
      </div>
    </div>
  );
};
