import { AccountDescription } from '@components/AccountDescription';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Typography } from '@elements/Typography';
import { Candidate } from '@models/candidate';
import { UserAccount } from '@models/UserAccount';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface SummaryCardProps {
  user?: UserAccount<Candidate>;
  className?: string;
}

export const ProfileSummaryCard = ({ user, className }: SummaryCardProps) => {
  const { t } = useTranslation('profile');
  const [descriptionUrl, setDescriptionUrl] = useState<string>();

  useEffect(() => {
    const _description = user?.accountInfo
      ? (user.accountInfo as Candidate).descriptionUrl
      : '';
    setDescriptionUrl(_description);
  }, [user]);

  return (
    <Card className={className}>
      <Typography variant="h3" className="mb-6 text-dark">
        {t('summary.title', { name: user?.accountInfo?.firstName })}
      </Typography>
      {descriptionUrl ? (
        <AccountDescription descriptionUrl={descriptionUrl} />
      ) : (
        <>
          <Typography variant="caption" className="mb-6 text-secondary">
            {t('summary.complete_intake_description')}
          </Typography>
          <Link to="/intake">
            <Button variant="secondary">{t('complete_intake')}</Button>
          </Link>
        </>
      )}
    </Card>
  );
};
