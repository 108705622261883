import { useToast } from '@elements/Toast';
import { clearToken } from '@services/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';

export const useLogout = () => {
  const { mutate } = useAccountContext();
  const navigate = useNavigate();
  const { t } = useTranslation('header');

  const { success } = useToast();

  const logout = async () => {
    try {
      clearToken();
      mutate();
      navigate('/');
    } catch (error) {
      console.error(error);
    }
    success(t('logout_success'));
  };
  // navigate('/');

  return logout;
};
