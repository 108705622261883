import PlaceholderCard from '@assets/placeholder-card.svg';
import { RoundedContainer } from '@components/RoundedContainer';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const VacanciesPage = () => {
  const { t } = useTranslation('vacancies');
  const isMobile = useIsMobile();
  return (
    <>
      <Container className={`${!isMobile && 'py-10'} relative`}>
        <Typography variant="h1">Vacancies</Typography>
        <div className="mt-10 grid grid-cols-1 gap-4 md:grid-cols-3">
          <img src={PlaceholderCard} className="w-full" />
          <img src={PlaceholderCard} className="w-full" />
          <img src={PlaceholderCard} className="w-full" />
        </div>
      </Container>
      <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
        <RoundedContainer className="animate-fade-in bg-white p-[40px] text-center">
          <Typography variant="h2" className="mb-[40px] text-dark">
            {t('create_first_vacancy')}
          </Typography>
          <Link to="/chat?intent=create_vacancy">
            <Button variant="secondary">{t('create_vacancy')}</Button>
          </Link>
        </RoundedContainer>
      </div>
    </>
  );
};
