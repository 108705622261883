import { Button } from '@elements/Button';
import { MarkdownEditor } from '@elements/MarkdownEditor';
import { MarkdownViewer } from '@elements/MarkdownViewer';
import { ChatIcon } from '@icons/index';
import { useProfileSummaryUpdate } from '@services/account/use-profile-summary-update';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export interface OverviewCompanyDescriptionProps {
  descriptionUrl: string;
}

export const AccountDescription = ({
  descriptionUrl
}: OverviewCompanyDescriptionProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editorState, setEditorState] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [markdownContent, setMarkdownContent] = useState('');
  const { t } = useTranslation('settings', { keyPrefix: 'overview' });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!descriptionUrl) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    fetch(descriptionUrl, { cache: 'no-store' })
      .then(res => res.text())
      .then(text => {
        setMarkdownContent(text);
        setEditorState(text);
        setIsLoading(false);
      });
  }, [descriptionUrl]);

  return (
    <>
      {isLoading ? (
        <div className="flex h-full items-center justify-center">
          <ClipLoader color="dark" size={36} />
        </div>
      ) : isEditing ? (
        <MarkdownEditor markdown={editorState} onChange={setEditorState} />
      ) : (
        <MarkdownViewer content={markdownContent} />
      )}
      <div className="mt-4 flex justify-end gap-2">
        {isEditing ? (
          <>
            <Button
              variant="secondary"
              onClick={async () => {
                setIsSaving(true);
                setMarkdownContent(editorState);
                await useProfileSummaryUpdate({
                  summary: editorState
                });
                setIsEditing(false);
                setIsSaving(false);
              }}
            >
              {isSaving ? (
                <ClipLoader color="dark" size={12} className="ml-2" />
              ) : (
                t('save_description')
              )}
            </Button>
            <Button onClick={() => setIsEditing(false)}>
              {t('cancel_editing')}
            </Button>
          </>
        ) : (
          <>
            <Link to="/intake" className="no-underline">
              <Button variant="secondary" className="flex items-center">
                <ChatIcon className="mr-2 w-[14px]" fill="white" />
                {t('talk_with_kora')}
              </Button>
            </Link>
            <Button onClick={() => setIsEditing(true)} variant="info">
              {t('edit_description')}
            </Button>
          </>
        )}
      </div>
    </>
  );
};
