import { toast, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const useToast = () => {
  return {
    success: successToast,
    error: errorToast,
    warning: warningToast,
    info: infoToast
  };
};
const toastOptions = {
  position: 'bottom-center' as ToastPosition,
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

const infoToast = (message: string) => {
  toast.info(message, toastOptions);
};

const successToast = (message: string) => {
  toast.success(message, toastOptions);
};

const errorToast = (message: string) => {
  toast.error(message, toastOptions);
};

const warningToast = (message: string) => {
  toast.warning(message, toastOptions);
};
