import notFoundImage from '@assets/not-found.svg';
import { Container } from '@elements/Container';

export const NotFoundPage = () => {
  return (
    <Container className="mt-[-44px] flex h-full items-center justify-center">
      <img src={notFoundImage} className="w-full max-w-[780px]" />
    </Container>
  );
};
