import { Button } from '@elements/Button';
import { useToast } from '@elements/Toast';
import { AccountType } from '@models/UserAccount';
import useLinkedInCallback from '@services/auth/use-linkedin-callback';
import { forgeFrontendUrl } from '@utils/UrlUtils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

const callbackUrl = forgeFrontendUrl('/register/linkedin');
const linkedInClientId = import.meta.env.VITE_LINKEDIN_CLIENT_ID;

export interface ILinkedInLoginButton {
  label: string;
  mode?: 'register' | 'login';
  accountType?: AccountType;
}

export const LinkedInLoginButton = ({
  label,
  mode = 'register',
  accountType
}: ILinkedInLoginButton) => {
  const { t } = useTranslation('register');
  const [accessCode, setAccessCode] = useState<string>();
  const { error } = useToast();

  useLinkedInCallback(mode, accessCode, accountType);

  const { linkedInLogin } = useLinkedIn({
    clientId: linkedInClientId,
    scope: 'openid,profile,email,w_member_social',
    redirectUri: callbackUrl, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: setAccessCode,
    onError: () => {
      error(t('linkedin_failed'));
    }
  });
  return (
    <Button
      variant="info"
      className="mx-auto w-80 bg-white"
      onClick={linkedInLogin}
    >
      {label}
    </Button>
  );
};
