import { QuickAction } from '@models/chatMessage';
import { lazy, Suspense, useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import ErrorQuickAction from './quick-actions/Error';

interface ChatBubbleProps {
  message: string;
  messageId: string;
  quickActions?: QuickAction[];
  isUser: boolean;
  isLast?: boolean;
  sendMessage?: (message: string) => void;
}

const loadComponents = async (
  quickActions: QuickAction[],
  messageId: string,
  sendMessage?: (message: string) => void
) => {
  const loadedComponents = await Promise.all(
    quickActions.map(async ({ name, data }) => {
      const quickActionName = name
        .split('_')
        .map(
          word =>
            word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()
        )
        .join('');
      try {
        const Component = lazy(() =>
          import(`./quick-actions/${quickActionName}.tsx`).catch(() => ({
            default: () => <ErrorQuickAction />
          }))
        );
        return (
          <Suspense fallback={<ErrorQuickAction />}>
            <Component
              key={quickActionName}
              data={data}
              messageId={messageId}
              sendMessage={sendMessage}
            />
          </Suspense>
        );
      } catch (error) {
        console.error(
          `Component ${quickActionName} could not be loaded`,
          error
        );
        return null;
      }
    })
  );
  return loadedComponents.filter(Boolean);
};

const ChatBubble = ({
  message,
  isUser,
  quickActions,
  messageId,
  isLast,
  sendMessage
}: ChatBubbleProps) => {
  const [components, setComponents] = useState<any[]>([]);

  useEffect(() => {
    if (quickActions) {
      loadComponents(quickActions, messageId, sendMessage).then(
        loadedComponents => {
          setComponents(loadedComponents);
        }
      );
    }
  }, [quickActions, sendMessage]);

  let bubbleClass =
    'max-w-[80%] rounded-[20px]  p-[16px_24px]  tracking-[0.6px] shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]';
  if (isUser) {
    bubbleClass = `${bubbleClass} bg-[#DBE2FD] text-right`;
  } else {
    bubbleClass = `${bubbleClass} bg-[#fff]`;
  }
  return (
    <div>
      {message !== '/' && (
        <div className={`flex ${isUser && 'justify-end'}`}>
          <div className={bubbleClass}>
            <Markdown className="whitespace-pre-wrap leading-none">
              {message}
            </Markdown>
          </div>
        </div>
      )}

      {components.length > 0 && (
        <Suspense fallback={<ErrorQuickAction />}>
          <div className="justify-left mt-2 flex max-w-[80%]">
            {components.map((component, index) => {
              return (
                <div
                  key={`quick-action-${index}`}
                  className="flex-1 rounded-[20px] bg-[#fff] p-[16px_24px] text-sm tracking-[0.6px] shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]"
                >
                  {component}
                </div>
              );
            })}
          </div>
        </Suspense>
      )}
    </div>
  );
};

export default ChatBubble;
