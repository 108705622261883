import { CheckCircle, ErrorCircle, Info } from '@icons/index';
import React from 'react';

type AlertProps = {
  variant: 'info' | 'danger' | 'success' | 'warning';
  icon?: React.ReactNode;
  children?: React.ReactNode;
};

export const Alert: React.FC<AlertProps> = ({ icon, variant, children }) => {
  const iconMap = {
    info: <Info />,
    danger: <ErrorCircle />,
    success: <CheckCircle />,
    warning: <Info />
  };
  // Define styles based on the variant
  const styles = {
    info: {
      container:
        'flex items-center p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50',
      icon: 'text-blue-800 dark:text-blue-400 mr-2'
    },
    danger: {
      container:
        'flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800',
      icon: 'text-red-800 dark:text-red-400 mr-2'
    },
    success: {
      container:
        'flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800',
      icon: 'text-green-800 dark:text-green-400 mr-2'
    },
    warning: {
      container:
        'flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800',
      icon: 'text-yellow-800 dark:text-yellow-300 mr-2'
    },
    dark: {
      container:
        'flex items-center p-4 text-sm text-gray-800 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600',
      icon: 'text-gray-800 dark:text-gray-300 mr-2'
    }
  };

  return (
    <div className={styles[variant].container} role="alert">
      <p className={styles[variant].icon}>{icon ?? iconMap[variant]}</p>
      <div>{children}</div>
    </div>
  );
};
