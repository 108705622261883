import { AccountType } from '@models/UserAccount';
import { HomePage } from '@pages/Home';
import { NotFoundPage } from '@pages/NotFound';
import { LoginPage, RegisterPage, VerifyPage } from '@pages/auth';
import { ProfilePage } from '@pages/candidate';
import { CandidateIntakePage } from '@pages/candidate/CandidateIntake';
import {
  EmployerIntakePage,
  SettingsPage,
  VacanciesPage
} from '@pages/employer';
import React from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Route, Routes } from 'react-router-dom';
import { useAccountContext } from './providers/UserContext';

export const KoraRoutes = () => {
  const { user } = useAccountContext();

  const employerRoutes = () => {
    return (
      <React.Fragment>
        <Route path="/intake" element={<EmployerIntakePage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/vacancies" element={<VacanciesPage />} />
      </React.Fragment>
    );
  };

  const candidateRoutes = () => {
    return (
      <React.Fragment>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/intake" element={<CandidateIntakePage />} />
      </React.Fragment>
    );
  };

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/register/linkedin" element={<LinkedInCallback />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/verify/:secret" element={<VerifyPage />} />

      {user?.accountType === AccountType.EMPLOYER && employerRoutes()}
      {user?.accountType === AccountType.CANDIDATE && candidateRoutes()}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
