export enum SenderType {
  USER = 'USER',
  LLM = 'LLM'
}

export interface QuickAction {
  name: string;
  data?: unknown;
}

export class ChatMessage {
  constructor(
    public id: string,
    public message: string,
    public quickActions: QuickAction[],
    public senderType: SenderType,
    public userId: string,
    public hasFollowUp: boolean,
    public createdAt: Date
  ) {}

  static fromJson(json: any): ChatMessage {
    return new ChatMessage(
      json.id,
      json.message,
      json.quickActions ? json.quickActions : [],
      json.senderType,
      json.userId,
      json.hasFollowUp,
      new Date(json.createdAt)
    );
  }
}
