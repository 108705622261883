import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { useTranslation } from 'react-i18next';
import candidate from '../assets/home_candidate.jpeg';
import employer from '../assets/home_employer.jpeg';

export const HomePage = () => {
  const { t } = useTranslation('home');
  return (
    <Container className="mt-[-1%] flex h-full items-center">
      <MetaTags title={t('home')} />
      <div className="grid grid-cols-2 gap-[60px]">
        <ButtonCard
          title={t('candidate_title')}
          image={candidate}
          buttonText={t('candidate_button_text')}
        />
        <ButtonCard
          title={t('employer_title')}
          image={employer}
          buttonText={t('employer_button_text')}
        />
      </div>
    </Container>
  );
};

const ButtonCard = ({
  title,
  image,
  buttonText
}: {
  title: string;
  image: string;
  buttonText: string;
}) => {
  return (
    <div className="relative flex flex-col gap-10 transition duration-200 hover:scale-105 hover:cursor-pointer">
      <div className="relative">
        <img src={image} className="rounded-[20px]" />
        <div className="absolute top-0 h-full w-full rounded-[20px] bg-gradient-to-b from-[rgba(242,240,253,0)] to-[#1A1E89]">
          <Typography
            variant="h2"
            className="absolute bottom-[32px] left-[24px] !text-4xl text-[#fff]"
          >
            {title}
          </Typography>
        </div>
      </div>
      <Button
        variant="secondary"
        className="w-full rounded-[80px] p-[20px] text-[24px] leading-[24px]"
      >
        {buttonText}
      </Button>
    </div>
  );
};
