import { CheckCircle } from '@icons/index';
import React from 'react';
import { Button } from './Button';
import './ProgressBar.css';
import { Typography } from './Typography';

interface ProgressBarStep {
  label: string;
  active: boolean;
}

interface ProgressBarProps {
  steps: ProgressBarStep[];
  completeAction?: () => void;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  steps,
  completeAction
}) => {
  const activeIndex = steps.findIndex(step => step.active);
  return (
    <div>
      <div className="flex w-full justify-between">
        {steps.map((step, index) => {
          if (
            completeAction &&
            activeIndex === steps.length - 1 &&
            index === activeIndex
          ) {
            return (
              <Button
                key={index}
                variant="secondary"
                className="flex animate-kora-pulse items-center px-2 py-1"
                onClick={async () => await completeAction?.()}
              >
                <CheckCircle className="mr-2 w-[14px]" fill="white" />
                <Typography variant="body2">{step.label}</Typography>
              </Button>
            );
          } else {
            return (
              <div
                key={index}
                className="flex flex-col items-center bg-white px-2 py-1 shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]"
              >
                <Typography variant="body2">{step.label}</Typography>
              </div>
            );
          }
        })}
      </div>
      <div className="relative w-full p-[0px_60px]">
        <div className="progress-bar-pre" />
        <ul className="progress-bar my-2 w-full">
          {steps.map((_, index) => (
            <li
              key={index}
              className={`${index <= activeIndex ? 'active' : ''}`}
            />
          ))}
        </ul>
        <div
          className={`progress-bar-post ${
            activeIndex === steps.length - 1 ? 'bg-[#3b55f6]' : 'bg-[#c0cdfb]'
          }`}
        />
      </div>
    </div>
  );
};
