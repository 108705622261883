export interface IDivider {
  orientation?: 'horizontal' | 'vertical';
  className?: string;
  [key: string]: unknown;
}

export const Divider = ({
  orientation = 'horizontal',
  className,
  ...props
}: IDivider) => {
  return (
    <hr
      className={`border-0 bg-gray ${orientation === 'horizontal' ? 'h-[2px] w-full' : 'w[2px] h-full'} ${className}`}
      {...props}
    />
  );
};
