import { useToast } from '@elements/Toast';
import { AccountType } from '@models/UserAccount';
import { KoraApi, setCookies } from '@services/api';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

export default function useLinkedInCallback(
  mode: 'register' | 'login',
  accessToken?: string,
  accountType?: AccountType
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { mutate } = useAccountContext();
  const { t } = useTranslation('register');
  const { error: errorToast, success: successToast } = useToast();

  const callback = async () => {
    setLoading(true);
    try {
      const response = await KoraApi.post('/auth/linkedin-callback', {
        accessToken,
        mode,
        accountType
      });
      setCookies({ ...response.data });
      mutate();
      setSuccess(true);
      successToast(t('linkedin_success'));
    } catch (error) {
      errorToast(t('linkedin_failed'));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!accessToken) return;
    callback();
  }, [accessToken]);

  const memoized = useMemo(() => {
    return { loading, success };
  }, [loading, success]);
  return memoized;
}
